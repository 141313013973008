<template>
  <page-main
    title="Leadership Skills"
    nextText="Technical Skills"
    buttonTabIndex="2"
    eventName="nav"
    @nav="nav('/HardSkills')"
    @save="save()"
  >
    <template v-slot:aside>
      <ul>
        <li>
          Leadership, or sometimes referred to as "soft" skills, are
          <b>very</b> important.
        </li>
        <li>
          These are skills that are not directly connected with your academics
          or your technical ablity - these are the skills you use when working
          with people. Be sure to capture that side of yourself here.
        </li>
        <li>We recommend adding <b>three to five</b> leadership skills.</li>
      </ul>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>Problem Solving</li>
              <li>Communication</li>
              <li>Willingness to Learn</li>
              <li>Adaptability</li>
              <li>Empathy</li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Type here to add or use drop down to select (one at a time)"
            titleText="Select or Add Leadership Skills:"
            :optionsList="baseSoftSkills"
            :selected.sync="softSkills"
            tabindex="1"
            autofocus
            :maxSelections="25"
          ></r-multi-select>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as Display from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "SoftSkills",
  components: {},
  data: () => ({}),
  methods: {
    save() {
      this.buildDisplay();
      this.saveSoftSkills();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("softSkill", ["saveSoftSkills"]),
    buildDisplay() {
      let displaySoftSkills = Display.BuildCommaString("", this.softSkills);
      this.softSkillsDisplay = displaySoftSkills;
      return [displaySoftSkills];
    },
  },
  computed: {
    displayValue: function () {
      return this.buildDisplay();
    },
    ...mapFields("softSkill", [
      "softSkills",
      "softSkillsDisplay",
      "baseSoftSkills",
    ]),
    tips() {
      return [
        "Select or type one skill at a time – that way you can be more flexible in how you organize them.",
        "Try to order your skills from strongest to weakest.",
        "We recommend adding three leadership skills, and no more than five.",
      ];
    },
  },
};
</script>
